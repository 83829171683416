<template>
  <div class="wrap" id="wrap">
    <!-- 表格和搜索右边盒子 -->
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="输入生产计划单号、商品编号、商品名称、条码"
            @isShowHighCom="getIsShowHigh"
            dateTimeType='daterange'
          />
          <!-- 条件 -->
          <div v-show="showHigh" class="x-f marT10">
            <div class="x-f">
              <span class="fS14MR10">商品</span>
              <SelectRemote
                class="marR10"
                :multiple="true"
                v-model="queryParams.goodsIds"
                style="width: 250px"
                :option="$select({ key: 'listGoods' }).option"
              />
            </div>
            <div class="x-f">
              <span class="fS14MR10">生产工厂</span>
              <SelectRemote
                class="marR15"
                :multiple="true"
                v-model="queryParams.produceDeptIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listProduceDept'
                  }).option
                "
              />
            </div>
            <div class="x-f">
              <span class="fS14MR10">部门</span>
              <SelectLocal
                :multiple="true"
                v-model="queryParams.deptIds"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listSimpleQuery'
                  }).option
                "
              />
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <!-- 表格商品信息卡片 -->
    <cardTitleCom card-title="生产领料差异分析" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <!-- 商品信息按钮和表格整体盒子 -->
        <div class="tableContent">
          <div>
            <el-table
              ref="multipleTable"
              v-loading="loadingTable"
              tooltip-effect="dark"
              :data="tableData"
              show-summary
              border
              :max-height="tableHeight"
              :height="tableHeight"
              :summary-method="getSummaries"
            >
              <el-table-column
                label="序号"
                align="center"
                width="80"
                type="index"
              />
              <el-table-column
                label="生产计划单日期"
                align="center"
                min-width="125"
                prop="billDate"
              />
              <el-table-column
                label="生产计划单号"
                align="center"
                min-width="160"
                prop="billNo"
              />

              <el-table-column
                label="商品编码"
                align="center"
                min-width="100"
                prop="goodsNo"
              />

              <el-table-column
                label="商品名称"
                align="center"
                min-width="100"
                prop="goodsName"
              />
              <el-table-column
                label="条码"
                align="center"
                prop="barcode"
                min-width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="规格"
                align="center"
                min-width="100"
                prop="goodsSpec"
              />

              <el-table-column
                label="单位"
                align="center"
                min-width="100"
                prop="unitName"
              />
              <el-table-column
                label="计划领用数"
                align="center"
                sortable
                prop="planPickingQty"
                min-width="120"
              />
              <el-table-column
                label="实际领用数"
                sortable
                align="center"
                prop="realityPickingQty"
                min-width="120"
              />
              <el-table-column
                label="领料差异数"
                sortable
                align="center"
                prop="pickingDifferenceQty"
                min-width="120"
              />
              <el-table-column
                label="领料差异率%"
                sortable
                align="center"
                prop="pickingDifferenceRate"
                min-width="120"
              />
            </el-table>
            <bottomPage
              v-model="queryParams"
              :getList="getList"
              :is-select-all="false"
              :total-com="total"
            />
          </div>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import bottomPage from '@/views/components/bottomPage' // 底部分页
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import { differenceAnalysisAPI } from '@/api/produce/report/collectdiffanalyse'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
export default {
  name: 'collectdiffanalyse',
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    SelectRemote,
    SelectLocal
  },
  data () {
    return {
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'billNos', name: '生产计划单号' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      // 是否显示高级搜索
      showHigh: false,
      // 表格遮罩
      loadingTable: false,
      tableData: [], // 配方表格
      total: 0, // 总条数
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 74
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        'api/system/produce/report/exportPickingDifferenceAnalysis',
        {
          ...this.queryParams
        },
        `生产领料差异分析表导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await differenceAnalysisAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //计算
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        if (index <= 7 || index == 11) {
          sums[index] = ''
          return
        }
        let values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = `${Number(sums[index].toFixed(2))} `
        } else {
          sums[index] = ''
        }
      })
      return sums
    }
  }
}
</script>
<style scoped lang="scss">
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 94px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
</style>
