<template>
  <div class="wrap" id="wrap">
    <cardTitleCom card-title="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            filterTimeStr="today"
            v-model="queryParams"
            :getList="getList"
            :drop-down-list="dropDownList"
            :is-search-input="true"
            is-search-input-title="商品编码、条码、商品名称"
            @isShowHighCom="getIsShowHigh"
            dateTimeType='daterange'
          />

          <div v-show="showHigh" class="marT10">
            <div class="x-f">
              <div class="x-f">
                <span class="fS14MR10">生产日期</span>
                <el-date-picker
                  class="inputWidth marR15"
                  size="mini"
                  v-model="datetime"
                  type="daterange"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
              <div class="x-f">
                <span class="fS14MR10">单据编号</span>
                <el-input
                  class="inputWidth marR15"
                  placeholder="全部"
                  v-model="queryParams.billNo"
                  size="mini"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">商品</span>
                <SelectRemote
                  class="marR15"
                  :multiple="true"
                  v-model="queryParams.goodsIds"
                  style="width: 250px"
                  :option="$select({ key: 'listGoods' }).option"
                />
              </div>
              <div class="x-f">
                <span class="fS14MR10">生产工厂</span>
                <SelectRemote
                  :multiple="true"
                  v-model="queryParams.produceDeptIds"
                  style="width: 250px"
                  :option="
                    $select({
                      key: 'listProduceDept'
                    }).option
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom card-title="生产计划统计表" class="headGoodsTable">
      <template #rightCardTitle>
        <el-button
          class="marR10"
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          >导出
        </el-button>
      </template>
      <template slot="cardContent">
        <div class="tableContent">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="生产计划单明细查询" name="detail">
              <el-table
                ref="multipleTable1"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="单据日期"
                  align="center"
                  prop="billDate"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="单据编号"
                  align="center"
                  min-width="200"
                  prop="billNo"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="生产日期"
                  align="center"
                  prop="produceDate"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="生产工厂编号"
                  align="center"
                  prop="deptNo"
                  min-width="120"
                />
                <el-table-column
                  label="生产工厂"
                  align="center"
                  prop="deptName"
                  min-width="120"
                />
                <el-table-column
                  label="领料状态"
                  align="center"
                  prop="leadMaterialStatusName"
                  min-width="120"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                  min-width="120"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  min-width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="规格"
                  align="center"
                  prop="goodsSpec"
                  min-width="120"
                />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="basUnitName"
                  min-width="120"
                />
                <el-table-column
                  label="基本单位数量"
                  sortable
                  align="center"
                  prop="basUnitQty"
                  min-width="130"
                />
                <el-table-column
                  label="单位"
                  align="center"
                  prop="unitName"
                  min-width="120"
                />
                <el-table-column
                  label="数量"
                  sortable
                  align="center"
                  prop="unitQty"
                  min-width="150"
                >
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="生产工厂汇总查询" name="dept">
              <el-table
                ref="multipleTable2"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="部门编码"
                  align="center"
                  prop="deptNo"
                />
                <el-table-column
                  label="生产工厂"
                  align="center"
                  prop="deptName"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  show-overflow-tooltip
                />
                <el-table-column label="规格" align="center" prop="goodsSpec" />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="basUnitName"
                />
                <el-table-column
                  label="基本单位数量"
                  sortable
                  align="center"
                  prop="basUnitQty"
                />
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="商品汇总查询" name="goods">
              <el-table
                ref="multipleTable3"
                v-loading="loadingTable"
                tooltip-effect="dark"
                :data="tableData"
                border
                show-summary
                :max-height="tableHeight"
                :height="tableHeight"
                :summary-method="getSummaries"
              >
                <el-table-column
                  label="序号"
                  align="center"
                  width="80"
                  type="index"
                />
                <el-table-column
                  label="商品编码"
                  align="center"
                  prop="goodsNo"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="商品名称"
                  align="center"
                  prop="goodsName"
                />
                <el-table-column
                  label="条码"
                  align="center"
                  prop="barcode"
                  show-overflow-tooltip
                />
                <el-table-column label="规格" align="center" prop="goodsSpec" />

                <el-table-column
                  label="基本单位"
                  align="center"
                  prop="basUnitName"
                />
                <el-table-column
                  label="基本单位数量"
                  sortable
                  align="center"
                  prop="basUnitQty"
                />
              </el-table>
            </el-tab-pane>
          </el-tabs>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :is-select-all="false"
            :total-com="total"
          />
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import seniorSearch from '@/views/components/seniorSearch' // 高级搜索
import bottomPage from '@/views/components/bottomPage' // 底部分页
import {
  listDetailsAPI,
  listGoodsAPI,
  listShopAPI
} from '@/api/produce/report/taskTotal' //生产计划汇总表
import { getTimeDate } from '@/utils/newDate/index.js' //年月日时分秒
import SelectLocal from '@/components/tablePage/select/select-local/index.vue'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
export default {
  name: 'taskTotal',
  components: {
    cardTitleCom,
    seniorSearch,
    bottomPage,
    SelectLocal,
    SelectRemote
  },
  data () {
    return {
      activeName: 'detail',
      datetime: '', //日期时间
      // 搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'goodsNos', name: '商品编号' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '条码' }
      ],
      loadingTable: false, // 表格的加载
      showHigh: false, // 是否显示高级搜索
      // 总条数
      total: 0,
      // 表格数据
      tableData: [],
      // 查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      activeNameType: {
        detail: 'multipleTable1',
        dept: 'multipleTable2',
        goods: 'multipleTable3'
      }, //枚举
      exportType: {
        detail: 'api/system/produce/plan/report/exportPlanDetails',
        dept: 'api/system/produce/plan/report/exportShop',
        goods: '/api/system/produce/plan/report/exportGoods'
      }, //定义导出枚举
      exportNameType: {
        detail: '生产计划统计表（生产计划单明细查询）',
        dept: '生产计划统计表（生产工厂汇总查询）',
        goods: '生产计划统计表（商品汇总查询）'
      }, //定义导出名称枚举
      //页面高度
      vivwH: 0,
      //搜索框高度
      searchH: 0,
      paginationH: 40
    }
  },
  watch: {
    //订单时间
    datetime (newVal) {
      if (newVal) {
        // console.log(newVal)
        this.queryParams.beginProduceDate = newVal[0]
        this.queryParams.endProduceDate = newVal[1]
      }
      if (!newVal) {
        this.queryParams.beginProduceDate = undefined
        this.queryParams.endProduceDate = undefined
      }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs[this.activeNameType[this.activeName]].doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 130
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    /** 导出按钮操作 */
    handleExport () {
      this.download(
        this.exportType[this.activeName],
        {
          ...this.queryParams
        },
        `${this.exportNameType[this.activeName]}导出${getTimeDate()}.xlsx`
      )
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    // 分页
    handleClick () {
      //this.queryParams.searchType = tab.name;
      this.getList()
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      let res = {}
      if (this.activeName == 'detail') {
        res = await listDetailsAPI(this.queryParams)
      } else if (this.activeName == 'goods') {
        res = await listGoodsAPI(this.queryParams)
      } else {
        res = await listShopAPI(this.queryParams)
      }
      this.tableData = res.rows
      this.total = res.total
      this.loadingTable = false
    },
    // 是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    },
    //计算
    getSummaries (param) {
      console.log(param)
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }

        let name = ''
        if (column.property === 'unitQty') {
          name = 'unitQty'

          sums[index] = 0 // 初始化合计值为0
          // sums[index] += " ";

          const values = data.map(item => item[name])

          sums[index] = values.reduce((prev, curr) => {
            return Number(prev) + curr
          }, sums[index]) // 累加到已初始化的合计值上
        } else if (column.property === 'basUnitQty') {
          name = 'basUnitQty'

          sums[index] = 0 // 初始化合计值为0
          //sums[index] += " ";

          const values = data.map(item => item[name])
          sums[index] = values.reduce((prev, curr) => {
            return prev + curr
          }, sums[index]) // 累加到已初始化的合计值上
        }
        // sums[index] = sums[index].toFixed(2); // 保留两位小数
      })
      return sums
    }
  }
}
</script>
<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  //商品表格卡片
  .headGoodsTable {
    height: calc(100% - 96px);
    .tableContent {
      margin: 6px 10px 0 10px;
    }
  }
}
//单据日期
::v-deep .el-date-editor--datetimerange.el-input,
.el-date-editor--datetimerange.el-input__inner {
  width: 358px;
}
</style>
