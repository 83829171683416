var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "输入单据编号、入库仓库、商品编码、商品名称、条码",
                    dateTimeType: "daterange",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "marT10",
                  },
                  [
                    _c("div", { staticClass: "x-f" }, [
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("商品"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listGoods" }).option,
                            },
                            model: {
                              value: _vm.queryParams.goodsIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "goodsIds", $$v)
                              },
                              expression: "queryParams.goodsIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("入库仓库"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({ key: "listStore" }).option,
                            },
                            model: {
                              value: _vm.queryParams.storeIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "storeIds", $$v)
                              },
                              expression: "queryParams.storeIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("生产工厂"),
                          ]),
                          _c("SelectRemote", {
                            staticClass: "marR15",
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listProduceDept",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.produceDeptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                              },
                              expression: "queryParams.produceDeptIds",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "x-f" },
                        [
                          _c("span", { staticClass: "fS14MR10" }, [
                            _vm._v("部门"),
                          ]),
                          _c("SelectLocal", {
                            staticStyle: { width: "250px" },
                            attrs: {
                              multiple: true,
                              option: _vm.$select({
                                key: "listSimpleQuery",
                              }).option,
                            },
                            model: {
                              value: _vm.queryParams.deptIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "deptIds", $$v)
                              },
                              expression: "queryParams.deptIds",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "产品入库明细表" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        "show-summary": "",
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据日期",
                          align: "center",
                          "min-width": "125",
                          prop: "billDate",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单据编号",
                          align: "center",
                          "min-width": "160",
                          prop: "billNo",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库仓库",
                          align: "center",
                          "min-width": "120",
                          prop: "storeName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产工厂",
                          align: "center",
                          "min-width": "120",
                          prop: "produceDeptName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "部门",
                          align: "center",
                          "min-width": "120",
                          prop: "deptName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品类别",
                          align: "center",
                          "min-width": "120",
                          prop: "categoryName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          "min-width": "120",
                          prop: "goodsNo",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "条码",
                          align: "center",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                          prop: "barcode",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "goodsSpec",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "入库数量",
                          align: "center",
                          prop: "unitQty",
                          "min-width": "120",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价",
                          align: "center",
                          sortable: "",
                          prop: "unitPurPrice",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价",
                          sortable: "",
                          align: "center",
                          prop: "unitSalePrice",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价金额(入库)",
                          sortable: "",
                          align: "center",
                          prop: "unitPurMoney",
                          "min-width": "140",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价金额(入库)",
                          sortable: "",
                          align: "center",
                          prop: "unitSaleMoney",
                          "min-width": "140",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "合格数量",
                          sortable: "",
                          align: "center",
                          prop: "unitEligibleQty",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价金额（合格）",
                          sortable: "",
                          align: "center",
                          prop: "unitPurMoneyEligible",
                          "min-width": "160",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价金额（合格）",
                          sortable: "",
                          align: "center",
                          prop: "unitSaleMoneyEligible",
                          "min-width": "150",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产次品",
                          sortable: "",
                          align: "center",
                          prop: "unitProduceInferiorQty",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价金额（生产次品）",
                          sortable: "",
                          align: "center",
                          prop: "unitPurMoneyProduceInferior",
                          "min-width": "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价金额（生产次品）",
                          sortable: "",
                          align: "center",
                          prop: "unitSaleMoneyProduceInferior",
                          "min-width": "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "包装次品",
                          align: "center",
                          "min-width": "120",
                          prop: "unitPackInferiorQty",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价金额（包装次品）",
                          sortable: "",
                          align: "center",
                          prop: "unitPurMoneyPackInferior",
                          "min-width": "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价金额（包装次品）",
                          sortable: "",
                          align: "center",
                          prop: "unitSaleMoneyPackInferior",
                          "min-width": "180",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核人",
                          align: "center",
                          "min-width": "120",
                          prop: "auditBy",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "审核时间",
                          align: "center",
                          prop: "auditTime",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }