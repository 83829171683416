var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { attrs: { "card-title": "查询", id: "search-card" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "navSearch" },
              [
                _c("seniorSearch", {
                  attrs: {
                    filterTimeStr: "today",
                    getList: _vm.getList,
                    "drop-down-list": _vm.dropDownList,
                    "is-search-input": true,
                    "is-search-input-title":
                      "输入生产计划单号、商品编号、商品名称、条码",
                    dateTimeType: "daterange",
                  },
                  on: { isShowHighCom: _vm.getIsShowHigh },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showHigh,
                        expression: "showHigh",
                      },
                    ],
                    staticClass: "x-f marT10",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("商品"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR10",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({ key: "listGoods" }).option,
                          },
                          model: {
                            value: _vm.queryParams.goodsIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "goodsIds", $$v)
                            },
                            expression: "queryParams.goodsIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("生产工厂"),
                        ]),
                        _c("SelectRemote", {
                          staticClass: "marR15",
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listProduceDept",
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.produceDeptIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "produceDeptIds", $$v)
                            },
                            expression: "queryParams.produceDeptIds",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c("span", { staticClass: "fS14MR10" }, [
                          _vm._v("部门"),
                        ]),
                        _c("SelectLocal", {
                          staticStyle: { width: "250px" },
                          attrs: {
                            multiple: true,
                            option: _vm.$select({
                              key: "listSimpleQuery",
                            }).option,
                          },
                          model: {
                            value: _vm.queryParams.deptIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryParams, "deptIds", $$v)
                            },
                            expression: "queryParams.deptIds",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "cardTitleCom",
        {
          staticClass: "headGoodsTable",
          attrs: { "card-title": "生产领料差异分析" },
          scopedSlots: _vm._u([
            {
              key: "rightCardTitle",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "marR10",
                      attrs: { icon: "el-icon-download", size: "mini" },
                      on: { click: _vm.handleExport },
                    },
                    [_vm._v("导出 ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingTable,
                          expression: "loadingTable",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableData,
                        "show-summary": "",
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                        "summary-method": _vm.getSummaries,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          width: "80",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产计划单日期",
                          align: "center",
                          "min-width": "125",
                          prop: "billDate",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "生产计划单号",
                          align: "center",
                          "min-width": "160",
                          prop: "billNo",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          "min-width": "100",
                          prop: "goodsNo",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          "min-width": "100",
                          prop: "goodsName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "条码",
                          align: "center",
                          prop: "barcode",
                          "min-width": "120",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          "min-width": "100",
                          prop: "goodsSpec",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          "min-width": "100",
                          prop: "unitName",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "计划领用数",
                          align: "center",
                          sortable: "",
                          prop: "planPickingQty",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "实际领用数",
                          sortable: "",
                          align: "center",
                          prop: "realityPickingQty",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "领料差异数",
                          sortable: "",
                          align: "center",
                          prop: "pickingDifferenceQty",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "领料差异率%",
                          sortable: "",
                          align: "center",
                          prop: "pickingDifferenceRate",
                          "min-width": "120",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      "is-select-all": false,
                      "total-com": _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }